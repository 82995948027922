import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container } from '@components/global';
import { GlobalLink } from '@components/Link';


const SHOWCASE = [
  {
    name: 'Tiny',
    included: ['100 Contacts', '5GB Storage', '10 Projects'],
    description: 'For 2 - 4 user managing their stuff',
    priceYearly: 'CHF 19.60',
    seatinformation: 'Per user per month. Billed yearly.',
    priceMonthly: 'CHF 23.90',
  },
  {
    name: 'Small',
    included: ['1000 Contacts', '30GB Storage', '100 Projects'],
    description: 'For 5 - 10 users',
    priceYearly: 'CHF 158.40',
    seatinformation: 'Per month. Billed yearly.',
    priceMonthly: 'CHF 171.90',
  },
  {
    name: 'Business',
    included: ['10000 Contacts', '100GB Storage', '1000 Projects'],
    description: 'For 11 - 25 users running high',
    priceYearly: 'CHF 279.80',
    seatinformation: 'Per month. Billed yearly.',
    priceMonthly: 'CHF 292.30',
  },
  {
    name: 'Enterprise',
    included: ['We see you need more'], 
    description: '26 and above users',
    priceYearly: '',
    seatinformation: 'Get in touch with us.',
    priceMonthly: 'We see - you need customization',
  },
];



const Pricing = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        art_showcase: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "undraw_a_moment_to_relax_bbpa" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (

      <Section id="pricing">
        <Container style={{ marginBottom: '5rem', position: 'relative',height: '100%' }}>
          <div style={{ margin: `10rem auto`}}>
          <h1>Pricing</h1>

          <h3 style={{ marginTop: '2rem'}} >Get the power to boost your productivity and lower your
          outgoings because of missed communication. Waisting <strong>1000.-- CHF </strong> for many tools. Not with us.</h3>
          </div>
          </Container>

        <Container style={{ position: 'relative',height: '100%' }}>

        <p>
         rocketbatch was built with a simple idea in mind: let's build beautiful productivity software which is
          simple and powerful at the same time so user actually use it. Between price plans we do not distinct between functionality. 
          <strong> One price - hole functionality.</strong> 
        </p>        

        <Container style={{ position: 'relative' }}>
          
          <PricesGrid>
            {SHOWCASE.map(({ name, image, description,priceYearly,included,seatinformation,priceMonthly }) => {
             /* const img = data.allFile.edges.find(
                ({ node }) => node.relativePath === image
              ).node;*/

              const listItems = included.map((item) =>
                <li>{item}</li>
              );

              return (
                <div>
                  {/* <Img fluid={img.childImageSharp.fluid} alt={name} />*/}
                  <Title>{name}</Title>
                  <Description>{description}</Description>
                  <Price>{priceYearly}</Price>
                  <Subtitle>{seatinformation}</Subtitle>
                  <br />
                  
                  <Description>Included:<br />{listItems}</Description>
                  <Subtitle>Price billed monthly: {priceMonthly}</Subtitle>
                </div>
              );

            })}
          </PricesGrid>

         
          <p style={{ marginTop: '150px' }}>
            You are looking for a solution to manage your <strong>personal</strong> stuff. Or your stuff with your <strong>family</strong>. Its <strong>free</strong> for personal and family usage. 
            Just <a href="https://rocketbatch.com/downloads" style={{color:'#0056E5', textDecoration:'none'}}>download </a>our client or soon publicly available in the browser.

            {/*   <a href="https://team.woanna.com/" style={{color:'#0056E5', textDecoration:'none'}}>start in the Browser</a>. */}
          </p>        
          <p>
            <small>(This includes 100 contacts, 5GB Storage and 10 Projects.)</small> 
          </p>             


          <Art>
            <Img fluid={data.art_showcase.childImageSharp.fluid} />
          </Art>
          <ArtMobile>
            <Img fluid={data.art_showcase.childImageSharp.fluid} />
          </ArtMobile>
        </Container>

        </Container>
      </Section>
    )}
  />
);



const Art = styled.figure`
  width: 400px;
  margin: -80px 0;
  position: absolute;
  top: 0;
  left: 30%;
  opacity:0.1;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 20%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const ArtMobile = styled.figure`
  width: 100%;
  margin: 0;
  display: none;
  margin-top: 64px;
  margin-bottom: -60%;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`;

const Title = styled.p`
  margin-top: 16px;
  margin-bottom:20px;
  color: ${props => props.theme.color.black.regular};
`;

const Subtitle = styled.p`
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.light};
`;

const Description = styled.p`
  margin-top: 5px;
  margin-bottom:10px;
  
  font-size: 12px;
  font-family: ${props => props.theme.font.secondary};
`;

const Price = styled.p`
  margin-top: 16px;
  margin-bottom:20px;
  color: ${props => props.theme.color.link};
  font-size:30px;
  font-family: ${props => props.theme.font.secondary};
`;

const PricesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-template-rows: min-content;
  grid-gap: 100px;
  justify-content: space-between;
  width: 100%;
  margin-top: 72px;
  border-color-right: gray;
  @media (max-width: ${props => props.theme.screen.lg}) {
    justify-content: start;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-gap: 24px;
  }
`;

export default Pricing;